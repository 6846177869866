:root {
  --primary-color: #005C7B;
  --secondary-color: #FF5733;
  --background-color: #fff;
  --text-color: #000;
  --border-color:#009199 ;
  --default-icon-color:#005C7B;
  --default-padding:12px;
  --default-gradient-color: linear-gradient(135deg, #009199 0%, #2C479A 100%)
}
.primary-color{
  color: var(--primary-color) !important;
}
.default-border{
  border: 1px solid var(--border-color);
}
.default-border-radius{
border-radius: 5px !important;
}

.gradient-text {
  background: var(--default-gradient-color); 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold; 
}



/* Login Section Start */
.bg {
  background: url('../Images/Login-bg-image[teal-blue].svg') no-repeat center center !important;
  background-size: cover !important; 
}

.white-bg {
  background: url('../Images/onboarding-bg-image[teal-blue].svg') no-repeat center center !important;
  background-size: cover !important; 
}
.progress-bg {
  background: url('../Images/sidebar-bg-image.jpg') no-repeat center center !important;
  background-size: cover !important; 
}
.modal-header-bg {
  background: url('../Images/modal-header.jpg') no-repeat center center !important;
  background-size: cover !important; 
}

.modal-bg {
  background: url('../Images/text-area-popup.jpg') no-repeat center center !important;
  background-size: cover !important; 
}
.custom-header{
  padding: 30px;
  border-radius: 12px 12px  0 0; 
}
.gradient-button{
  border-radius: 5px ;  
  min-width: 200px ;
  background: var(--default-gradient-color);
  color: white;
  height: 44px !important;
  border: 1px solid var(--border-color);
  font-size: 14;
  font-family: 'Inter';
  font-weight: '600';
  word-wrap: 'break-word'
}
.skip-button{
  border-radius: 5px ;  
  min-width: 185px ;
  background: var(--default-gradient-color); 
  color: white;
  height: 44px !important;
  border: 1px solid var(--border-color);
  font-size: 14;
  font-family: 'Inter';
  font-weight: '600';
  word-wrap: 'break-word';

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: bold; 
}
.color-black{
  color: black !important;
}

.files-ui-dropzone-children-container label{
  color: black;
  font-size: 15px !important;
}



 /* Dashboard Styling */

.inner-card{
width: 160.16; 
height: 143.52;
 background: linear-gradient(180deg, #EAF5FF 0%);
  border-radius: 13.11;
}
 

/* Margin */
.m-1 { margin-top: 0.25rem !important; }
.m-2 { margin-top: 0.5rem !important; }
.m-3 { margin-top: 0.75rem !important; }
.m-4 { margin-top: 1rem !important; }
.m-5 { margin-top: 1.25rem !important; }
.m-6 { margin-top: 1.5rem !important; }
.m-7 { margin-top: 1.75rem !important; }
.m-8 { margin-top: 2rem !important; }
.m-9 { margin-top: 2.25rem !important; }
.m-10 { margin-top: 2.5rem !important; }

/* Margin Top */
.mt-1 { margin-top: 0.25rem !important; }
.mt-2 { margin-top: 0.5rem !important; }
.mt-3 { margin-top: 0.75rem !important; }
.mt-4 { margin-top: 1rem !important; }
.mt-5 { margin-top: 1.25rem !important; }
.mt-6 { margin-top: 1.5rem !important; }
.mt-7 { margin-top: 1.75rem !important; }
.mt-8 { margin-top: 2rem !important; }
.mt-9 { margin-top: 2.25rem !important; }
.mt-10 { margin-top: 2.5rem !important; }
.mt-11 { margin-top: 2.75rem !important; }
.mt-12 { margin-top: 3rem !important; }
.mt-13 { margin-top: 3.25rem !important; }
.mt-14 { margin-top: 3.5rem !important; }
.mt-15 { margin-top: 3.75rem !important; }
.mt-16 { margin-top: 4rem !important; }
.mt-17 { margin-top: 4.25rem !important; }
.mt-18 { margin-top: 4.5rem !important; }
.mt-19 { margin-top: 4.75rem !important; }
.mt-20 { margin-top: 5rem !important; }
.mt-21 { margin-top: 5.25rem !important; }
.mt-22 { margin-top: 5.5rem !important; }
.mt-23 { margin-top: 5.75rem !important; }
.mt-24 { margin-top: 6rem !important; }
.mt-25 { margin-top: 6.25rem !important; }
.mt-26 { margin-top: 6.5rem !important; }
.mt-27 { margin-top: 6.75rem !important; }
.mt-28 { margin-top: 7rem !important; }
.mt-29 { margin-top: 7.25rem !important; }
.mt-30 { margin-top: 7.5rem !important; }
.mt-31 { margin-top: 7.75rem !important; }
.mt-32 { margin-top: 8rem !important; }
.mt-33 { margin-top: 8.25rem !important; }
.mt-34 { margin-top: 8.5rem !important; }
.mt-35 { margin-top: 8.75rem !important; }
.mt-36 { margin-top: 9rem !important; }
.mt-37 { margin-top: 9.25rem !important; }
.mt-38 { margin-top: 9.5rem !important; }
.mt-39 { margin-top: 9.75rem !important; }
.mt-40 { margin-top: 10rem !important; }
.mt-41 { margin-top: 10.25rem !important; }
.mt-42 { margin-top: 10.5rem !important; }
.mt-43 { margin-top: 10.75rem !important; }
.mt-44 { margin-top: 11rem !important; }
.mt-45 { margin-top: 11.25rem !important; }
.mt-46 { margin-top: 11.5rem !important; }
.mt-47 { margin-top: 11.75rem !important; }
.mt-48 { margin-top: 12rem !important; }
.mt-49 { margin-top: 12.25rem !important; }
.mt-50 { margin-top: 12.5rem !important; }
.mt-51 { margin-top: 12.75rem !important; }
.mt-52 { margin-top: 13rem !important; }
.mt-53 { margin-top: 13.25rem !important; }
.mt-54 { margin-top: 13.5rem !important; }
.mt-55 { margin-top: 13.75rem !important; }
.mt-56 { margin-top: 14rem !important; }
.mt-57 { margin-top: 14.25rem !important; }
.mt-58 { margin-top: 14.5rem !important; }
.mt-59 { margin-top: 14.75rem !important; }
.mt-60 { margin-top: 15rem !important; }
.mt-61 { margin-top: 15.25rem !important; }
.mt-62 { margin-top: 15.5rem !important; }
.mt-63 { margin-top: 15.75rem !important; }
.mt-64 { margin-top: 16rem !important; }
.mt-65 { margin-top: 16.25rem !important; }
.mt-66 { margin-top: 16.5rem !important; }
.mt-67 { margin-top: 16.75rem !important; }
.mt-68 { margin-top: 17rem !important; }
.mt-69 { margin-top: 17.25rem !important; }
.mt-70 { margin-top: 17.5rem !important; }
.mt-71 { margin-top: 17.75rem !important; }
.mt-72 { margin-top: 18rem !important; }
.mt-73 { margin-top: 18.25rem !important; }
.mt-74 { margin-top: 18.5rem !important; }
.mt-75 { margin-top: 18.75rem !important; }
.mt-76 { margin-top: 19rem !important; }
.mt-77 { margin-top: 19.25rem !important; }
.mt-78 { margin-top: 19.5rem !important; }
.mt-79 { margin-top: 19.75rem !important; }
.mt-80 { margin-top: 20rem !important; }
.mt-81 { margin-top: 20.25rem !important; }
.mt-82 { margin-top: 20.5rem !important; }
.mt-83 { margin-top: 20.75rem !important; }
.mt-84 { margin-top: 21rem !important; }
.mt-85 { margin-top: 21.25rem !important; }
.mt-86 { margin-top: 21.5rem !important; }
.mt-87 { margin-top: 21.75rem !important; }
.mt-88 { margin-top: 22rem !important; }
.mt-89 { margin-top: 22.25rem !important; }
.mt-90 { margin-top: 22.5rem !important; }
.mt-91 { margin-top: 22.75rem !important; }
.mt-92 { margin-top: 23rem !important; }
.mt-93 { margin-top: 23.25rem !important; }
.mt-94 { margin-top: 23.5rem !important; }
.mt-95 { margin-top: 23.75rem !important; }
.mt-96 { margin-top: 24rem !important; }
.mt-97 { margin-top: 24.25rem !important; }
.mt-98 { margin-top: 24.5rem !important; }
.mt-99 { margin-top: 24.75rem !important; }
.mt-100 { margin-top: 25rem !important; }


/* Margin Bottom */
.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 0.75rem !important; }
.mb-4 { margin-bottom: 1rem !important; }
.mb-5 { margin-bottom: 1.25rem !important; }
.mb-6 { margin-bottom: 1.5rem !important; }
.mb-7 { margin-bottom: 1.75rem !important; }
.mb-8 { margin-bottom: 2rem !important; }
.mb-9 { margin-bottom: 2.25rem !important; }
.mb-10 { margin-bottom: 2.5rem !important; }

/* Margin Left */
.ml-1 { margin-left: 0.25rem !important ; }
.ml-2 { margin-left: 0.5rem !important ; }
.ml-3 { margin-left: 0.75rem !important ; }
.ml-4 { margin-left: 1rem !important ; }
.ml-5 { margin-left: 1.25rem !important ; }
.ml-6 { margin-left: 1.5rem !important ; }
.ml-7 { margin-left: 1.75rem !important ; }
.ml-8 { margin-left: 2rem !important ; }
.ml-9 { margin-left: 2.25rem !important ; }
.ml-10 { margin-left: 2.5rem !important ; }

/* Margin Right */
.mr-1 { margin-right: 0.25rem !important ; }
.mr-2 { margin-right: 0.5rem !important ; }
.mr-3 { margin-right: 0.75rem !important ; }
.mr-4 { margin-right: 1rem !important ; }
.mr-5 { margin-right: 1.25rem !important ; }
.mr-6 { margin-right: 1.5rem !important ; }
.mr-7 { margin-right: 1.75rem !important ; }
.mr-8 { margin-right: 2rem !important ; }
.mr-9 { margin-right: 2.25rem !important ; }
.mr-10 { margin-right: 2.5rem !important ; }

/* Padding Top */
.pt-1 { padding-top: 0.25rem !important ; }
.pt-2 { padding-top: 0.5rem !important ; }
.pt-3 { padding-top: 0.75rem !important ; }
.pt-4 { padding-top: 1rem !important ; }
.pt-5 { padding-top: 1.25rem !important ; }
.pt-6 { padding-top: 1.5rem !important ; }
.pt-7 { padding-top: 1.75rem !important ; }
.pt-8 { padding-top: 2rem !important ; }
.pt-9 { padding-top: 2.25rem !important ; }
.pt-10 { padding-top: 2.5rem !important ; }

/* Padding Bottom */
.pb-1 { padding-bottom: 0.25rem !important ; }
.pb-2 { padding-bottom: 0.5rem !important ; }
.pb-3 { padding-bottom: 0.75rem !important ; }
.pb-4 { padding-bottom: 1rem !important ; }
.pb-5 { padding-bottom: 1.25rem !important ; }
.pb-6 { padding-bottom: 1.5rem !important ; }
.pb-7 { padding-bottom: 1.75rem !important ; }
.pb-8 { padding-bottom: 2rem !important ; }
.pb-9 { padding-bottom: 2.25rem !important ; }
.pb-10 { padding-bottom: 2.5rem !important ; }

/* Padding Left */
.pl-1 { padding-left: 0.25rem !important ; }
.pl-2 { padding-left: 0.5rem !important ; }
.pl-3 { padding-left: 0.75rem !important ; }
.pl-4 { padding-left: 1rem !important ; }
.pl-5 { padding-left: 1.25rem !important ; }
.pl-6 { padding-left: 1.5rem !important ; }
.pl-7 { padding-left: 1.75rem !important ; }
.pl-8 { padding-left: 2rem !important ; }
.pl-9 { padding-left: 2.25rem !important ; }
.pl-10 { padding-left: 2.5rem !important ; }

/* Padding Right */
.pr-1 { padding-right: 0.25rem !important ; }
.pr-2 { padding-right: 0.5rem !important ; }
.pr-3 { padding-right: 0.75rem !important ; }
.pr-4 { padding-right: 1rem !important ; }
.pr-5 { padding-right: 1.25rem !important ; }
.pr-6 { padding-right: 1.5rem !important ; }
.pr-7 { padding-right: 1.75rem !important ; }
.pr-8 { padding-right: 2rem !important ; }
.pr-9 { padding-right: 2.25rem !important ; }
.pr-10 { padding-right: 2.5rem !important ; }


.top-logo-container {
    position: absolute;
    top: 6%;
    left: 3%;
    transform: translateY(-50%);
    text-align: center;
  }
  


  .logo-maverick {
    background-size: 70%;
    color: #fff;
    font-family: Mulish;
    font-size: 1.7rem;
    font-style: normal;
    font-weight: 600;
    line-height: 300%;
  }
  

  .w-1 { width: 1% !important; }
  .w-2 { width: 2% !important; }
  .w-3 { width: 3% !important; }
  .w-4 { width: 4% !important; }
  .w-5 { width: 5% !important; }
  .w-6 { width: 6% !important; }
  .w-7 { width: 7% !important; }
  .w-8 { width: 8% !important; }
  .w-9 { width: 9% !important; }
  .w-10 { width: 10% !important; }
  .w-11 { width: 11% !important; }
  .w-12 { width: 12% !important; }
  .w-13 { width: 13% !important; }
  .w-14 { width: 14% !important; }
  .w-15 { width: 15% !important; }
  .w-16 { width: 16% !important; }
  .w-17 { width: 17% !important; }
  .w-18 { width: 18% !important; }
  .w-19 { width: 19% !important; }
  .w-20 { width: 20% !important; }
  .w-21 { width: 21% !important; }
  .w-22 { width: 22% !important; }
  .w-23 { width: 23% !important; }
  .w-24 { width: 24% !important; }
  .w-25 { width: 25% !important; }
  .w-26 { width: 26% !important; }
  .w-27 { width: 27% !important; }
  .w-28 { width: 28% !important; }
  .w-29 { width: 29% !important; }
  .w-30 { width: 30% !important; }
  .w-31 { width: 31% !important; }
  .w-32 { width: 32% !important; }
  .w-33 { width: 33% !important; }
  .w-34 { width: 34% !important; }
  .w-35 { width: 35% !important; }
  .w-36 { width: 36% !important; }
  .w-37 { width: 37% !important; }
  .w-38 { width: 38% !important; }
  .w-39 { width: 39% !important; }
  .w-40 { width: 40% !important; }
  .w-41 { width: 41% !important; }
  .w-42 { width: 42% !important; }
  .w-43 { width: 43% !important; }
  .w-44 { width: 44% !important; }
  .w-45 { width: 45% !important; }
  .w-46 { width: 46% !important; }
  .w-47 { width: 47% !important; }
  .w-48 { width: 48% !important; }
  .w-49 { width: 49% !important; }
  .w-50 { width: 50% !important; }
  .w-51 { width: 51% !important; }
  .w-52 { width: 52% !important; }
  .w-53 { width: 53% !important; }
  .w-54 { width: 54% !important; }
  .w-55 { width: 55% !important; }
  .w-56 { width: 56% !important; }
  .w-57 { width: 57% !important; }
  .w-58 { width: 58% !important; }
  .w-59 { width: 59% !important; }
  .w-60 { width: 60% !important; }
  .w-61 { width: 61% !important; }
  .w-62 { width: 62% !important; }
  .w-63 { width: 63% !important; }
  .w-64 { width: 64% !important; }
  .w-65 { width: 65% !important; }
  .w-66 { width: 66% !important; }
  .w-67 { width: 67% !important; }
  .w-68 { width: 68% !important; }
  .w-69 { width: 69% !important; }
  .w-70 { width: 70% !important; }
  .w-71 { width: 71% !important; }
  .w-72 { width: 72% !important; }
  .w-73 { width: 73% !important; }
  .w-74 { width: 74% !important; }
  .w-75 { width: 75% !important; }
  .w-76 { width: 76% !important; }
  .w-77 { width: 77% !important; }
  .w-78 { width: 78% !important; }
  .w-79 { width: 79% !important; }
  .w-80 { width: 80% !important; }
  .w-81 { width: 81% !important; }
  .w-82 { width: 82% !important; }
  .w-83 { width: 83% !important; }
  .w-84 { width: 84% !important; }
  .w-85 { width: 85% !important; }
  .w-86 { width: 86% !important; }
  .w-87 { width: 87% !important; }
  .w-88 { width: 88% !important; }
  .w-89 { width: 89% !important; }
  .w-90 { width: 90% !important; }
  .w-91 { width: 91% !important; }
  .w-92 { width: 92% !important; }
  .w-93 { width: 93% !important; }
  .w-94 { width: 94% !important; }
  .w-95 { width: 95% !important; }
  .w-96 { width: 96% !important; }
  .w-97 { width: 97% !important; }
  .w-98 { width: 98% !important; }
  .w-99 { width: 99% !important; }
  .w-100 { width: 100% !important; }
  

  .h-1 { height: 1% !important; }
.h-2 { height: 2% !important; }
.h-3 { height: 3% !important; }
.h-4 { height: 4% !important; }
.h-5 { height: 5% !important; }
.h-6 { height: 6% !important; }
.h-7 { height: 7% !important; }
.h-8 { height: 8% !important; }
.h-9 { height: 9% !important; }
.h-10 { height: 10% !important; }
.h-11 { height: 11% !important; }
.h-12 { height: 12% !important; }
.h-13 { height: 13% !important; }
.h-14 { height: 14% !important; }
.h-15 { height: 15% !important; }
.h-16 { height: 16% !important; }
.h-17 { height: 17% !important; }
.h-18 { height: 18% !important; }
.h-19 { height: 19% !important; }
.h-20 { height: 20% !important; }
.h-21 { height: 21% !important; }
.h-22 { height: 22% !important; }
.h-23 { height: 23% !important; }
.h-24 { height: 24% !important; }
.h-25 { height: 25% !important; }
.h-26 { height: 26% !important; }
.h-27 { height: 27% !important; }
.h-28 { height: 28% !important; }
.h-29 { height: 29% !important; }
.h-30 { height: 30% !important; }
.h-31 { height: 31% !important; }
.h-32 { height: 32% !important; }
.h-33 { height: 33% !important; }
.h-34 { height: 34% !important; }
.h-35 { height: 35% !important; }
.h-36 { height: 36% !important; }
.h-37 { height: 37% !important; }
.h-38 { height: 38% !important; }
.h-39 { height: 39% !important; }
.h-40 { height: 40% !important; }
.h-41 { height: 41% !important; }
.h-42 { height: 42% !important; }
.h-43 { height: 43% !important; }
.h-44 { height: 44% !important; }
.h-45 { height: 45% !important; }
.h-46 { height: 46% !important; }
.h-47 { height: 47% !important; }
.h-48 { height: 48% !important; }
.h-49 { height: 49% !important; }
.h-50 { height: 50% !important; }
.h-51 { height: 51% !important; }
.h-52 { height: 52% !important; }
.h-53 { height: 53% !important; }
.h-54 { height: 54% !important; }
.h-55 { height: 55% !important; }
.h-56 { height: 56% !important; }
.h-57 { height: 57% !important; }
.h-58 { height: 58% !important; }
.h-59 { height: 59% !important; }
.h-60 { height: 60% !important; }
.h-61 { height: 61% !important; }
.h-62 { height: 62% !important; }
.h-63 { height: 63% !important; }
.h-64 { height: 64% !important; }
.h-65 { height: 65% !important; }
.h-66 { height: 66% !important; }
.h-67 { height: 67% !important; }
.h-68 { height: 68% !important; }
.h-69 { height: 69% !important; }
.h-70 { height: 70% !important; }
.h-71 { height: 71% !important; }
.h-72 { height: 72% !important; }
.h-73 { height: 73% !important; }
.h-74 { height: 74% !important; }
.h-75 { height: 75% !important; }
.h-76 { height: 76% !important; }
.h-77 { height: 77% !important; }
.h-78 { height: 78% !important; }
.h-79 { height: 79% !important; }
.h-80 { height: 80% !important; }
.h-81 { height: 81% !important; }
.h-82 { height: 82% !important; }
.h-83 { height: 83% !important; }
.h-84 { height: 84% !important; }
.h-85 { height: 85% !important; }
.h-86 { height: 86% !important; }
.h-87 { height: 87% !important; }
.h-88 { height: 88% !important; }
.h-89 { height: 89% !important; }
.h-90 { height: 90% !important; }
.h-91 { height: 91% !important; }
.h-92 { height: 92% !important; }
.h-93 { height: 93% !important; }
.h-94 { height: 94% !important; }
.h-95 { height: 95% !important; }
.h-96 { height: 96% !important; }
.h-97 { height: 97% !important; }
.h-98 { height: 98% !important; }
.h-99 { height: 99% !important; }
.h-100 { height: 100% !important; }



/* Assuming the root font-size is 16px */
.font-size-5 { font-size: 0.3125rem !important ; } /* 5px */
.font-size-6 { font-size: 0.375rem !important ; }  /* 6px */
.font-size-7 { font-size: 0.4375rem !important ; } /* 7px */
.font-size-8 { font-size: 0.5rem !important ; }    /* 8px */
.font-size-9 { font-size: 0.5625rem !important ; } /* 9px */
.font-size-10 { font-size: 0.625rem !important ; } /* 10px */
.font-size-11 { font-size: 0.6875rem !important ; }/* 11px */
.font-size-12 { font-size: 0.75rem !important ; }  /* 12px */
.font-size-13 { font-size: 0.8125rem !important ; }/* 13px */
.font-size-14 { font-size: 0.875rem !important ; } /* 14px */
.font-size-15 { font-size: 0.9375rem !important ; }/* 15px */
.font-size-16 { font-size: 1rem !important ; }     /* 16px */
.font-size-17 { font-size: 1.0625rem !important ; }/* 17px */
.font-size-18 { font-size: 1.125rem !important ; } /* 18px */
.font-size-19 { font-size: 1.1875rem !important ; }/* 19px */
.font-size-20 { font-size: 1.25rem !important ; }  /* 20px */
.font-size-21 { font-size: 1.3125rem !important ; }/* 21px */
.font-size-22 { font-size: 1.375rem !important ; } /* 22px */
.font-size-23 { font-size: 1.4375rem !important ; }/* 23px */
.font-size-24 { font-size: 1.5rem !important ; }   /* 24px */
.font-size-25 { font-size: 1.5625rem !important ; }/* 25px */
.font-size-26 { font-size: 1.625rem !important ; } /* 26px */
.font-size-27 { font-size: 1.6875rem !important ; }/* 27px */
.font-size-28 { font-size: 1.75rem !important ; }  /* 28px */
.font-size-29 { font-size: 1.8125rem !important ; }/* 29px */
.font-size-30 { font-size: 1.875rem !important ; } /* 30px */
.font-size-31 { font-size: 1.9375rem !important ; }/* 31px */
.font-size-32 { font-size: 2rem !important ; }     /* 32px */
.font-size-33 { font-size: 2.0625rem !important ; }/* 33px */
.font-size-34 { font-size: 2.125rem !important ; } /* 34px */
.font-size-35 { font-size: 2.1875rem !important ; }/* 35px */
.font-size-36 { font-size: 2.25rem !important ; }  /* 36px */
.font-size-37 { font-size: 2.3125rem !important ; }/* 37px */
.font-size-38 { font-size: 2.375rem !important ; } /* 38px */
.font-size-39 { font-size: 2.4375rem !important ; }/* 39px */
.font-size-40 { font-size: 2.5rem !important ; }   /* 40px */
.font-size-41 { font-size: 2.5625rem !important ; }/* 41px */
.font-size-42 { font-size: 2.625rem !important ; } /* 42px */
.font-size-43 { font-size: 2.6875rem !important ; }/* 43px */
.font-size-44 { font-size: 2.75rem !important ; }  /* 44px */
.font-size-45 { font-size: 2.8125rem !important ; }/* 45px */
.font-size-46 { font-size: 2.875rem !important ; } /* 46px */
.font-size-47 { font-size: 2.9375rem !important ; }/* 47px */
.font-size-48 { font-size: 3rem !important ; }     /* 48px */
.font-size-49 { font-size: 3.0625rem !important ; }/* 49px */
.font-size-50 { font-size: 3.125rem !important ; } /* 50px */


.font-weight-100 { font-weight: 100 !important; } /* Thin */
.font-weight-200 { font-weight: 200 !important; } /* Extra Light */
.font-weight-300 { font-weight: 300 !important; } /* Light */
.font-weight-400 { font-weight: 400 !important; } /* Regular / Normal */
.font-weight-500 { font-weight: 500 !important; } /* Medium */
.font-weight-600 { font-weight: 600 !important; } /* Semi-Bold */
.font-weight-700 { font-weight: 700 !important; } /* Bold */
.font-weight-800 { font-weight: 800 !important; } /* Extra Bold */
.font-weight-900 { font-weight: 900 !important; } /* Black */
.font-weight-1000 { font-weight: 1000 !important; } /* Ultra Black (Rarely Used) */



  /* **Project Card** */


  .project-card {
    max-width: 300px;
    height: 140px;
    background: #EAF1FB;
    display: flex;
    flex-direction: column;
    padding: 12px 20px;
    gap: 0.5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.123);
    border-radius: 12px;
  }
  
 
    
  .bellIcon {
    width: 50px;
    margin: 2px 0px;
  }
  
  .bellIcon path {
    fill:#81A1F1;
  }
  
  .project-heading {
    color: #666666;
    font-weight: 500;
    font-size: 0.8em;
    text-transform: capitalize;
    letter-spacing: 0.05cap;
    margin-bottom: 0 !important  ;
  }
  
  .project-para {
    color: #333333;
    font-size: 0.6em;
    font-weight: 500;
   
  }
  
  .button-container {
    display: flex;
    margin-top:12px ;
    margin-bottom: 12px !important;
    justify-content: center;
  
  }
  
  .AllowBtn {
    background-color:#81A1F1;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.7em;
    font-weight: 600;
    cursor: pointer;
    float: right;
  }
  
  .NotnowBtn {
   
    color: rgb(84, 63, 133);
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.7em;
    cursor: pointer;
    border-radius: 12px;
  }
  
  .NotnowBtn:hover {
    background-color: EAF1FB;
  }
  
  .AllowBtn:hover {
    background-color:#658BEE;
  }
  

  .common-border-radius{
    border-radius: 12px !important;
  }

  .cursor-pointer{
    cursor: pointer;
  }
  label {
    color: black !important;
    font-weight: 500;
  }
  .required-star {
    color: red !important;
    font-weight: 500;
  }


  .normal-button{
    border-radius: 5px ;
    min-width: 250px ;
    background-color: rgba(0, 0, 0, 0.705);
    color: #fff;
    max-height: 45px !important;
  
  }
  .normal-button:hover {
    background-color: black;
    color: white;
  }

  .rounded-circle{
    background-color: rgba(0, 0, 0, 0.753);
    color: white;
    width: 26px;
    height: 26px;
  }
  .common-padding {
    padding: 12px !important;
  }
  .common-margin {
    padding: 3px !important;
  }
  
  

  